export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15')
];

export const server_loads = [2];

export const dictionary = {
		"/(app)": [~4,[2]],
		"/(app)/marketplace": [5,[2]],
		"/(app)/privacy": [6,[2]],
		"/(app)/tanknft/[network]/[contract]/[address]": [9,[2]],
		"/(app)/tank/[address]": [7,[2]],
		"/(app)/tank/[network]/[contract]/[address]/sale": [8,[2]],
		"/(app)/user/history": [10,[2,3]],
		"/(app)/user/inventory": [11,[2,3]],
		"/(app)/user/staking": [12,[2,3]],
		"/(app)/user/trading": [13,[2,3]],
		"/(app)/user/wallet": [14,[2,3]],
		"/(app)/user/wishList": [15,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';